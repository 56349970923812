import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { CssBaseline } from '@mui/material';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from '/@/routes';
import { PageErrorState } from '/@/store/app/pageError';
import ScrollToTop from '/@/utils/scrollToTop';
import { AuthenticatedGuard } from '/@/components/shared/authenticatedGuard/Index';
import { PageFadeIn } from '/@/components/shared/pageFadeIn/Presenter';
import { ErrorPage } from './shared/errorPage/Index';
import { ApolloClientErrors } from '../store/api/handleError';
import { DataLayerProvider } from './shared/dataLayerContext';

type OwnProps = {
  pageError: PageErrorState;
};

const App: React.VFC<OwnProps> = ({ pageError }) => {
  const gtmParams = {
    id: process.env.VITE_GOOGLE_TAG_MANAGER_ID ?? '',
    environment: {
      gtm_auth: process.env.VITE_GOOGLE_TAG_MANAGER_AUTH ?? '',
      gtm_preview: process.env.VITE_GOOGLE_TAG_MANAGER_PREVIEW ?? '',
    },
  };

  return (
    <div className="App">
      <GTMProvider state={gtmParams}>
        <DataLayerProvider>
          <CssBaseline />
          <ScrollToTop />
          {/* MEMO: 401エラーの場合はログイン画面にリダイレクトさせるため、
            ErrorPageの条件から除外している */}
          {pageError &&
          pageError.status !==
            ApolloClientErrors.AUTHENTICATION_ERROR.status ? (
            <ErrorPage error={pageError} />
          ) : (
            <Routes>
              {routes.map((route) =>
                route.private ? (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <AuthenticatedGuard>
                        <PageFadeIn>{route.element}</PageFadeIn>
                      </AuthenticatedGuard>
                    }
                  />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<PageFadeIn>{route.element}</PageFadeIn>}
                  />
                ),
              )}
            </Routes>
          )}
        </DataLayerProvider>
      </GTMProvider>
    </div>
  );
};

export { App };
