import { getBrandsConnection } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { GetBrandsConnectionResponse } from '/@/api/graphql/internalApi/getBrandsConnection/index';
import type { AppThunk } from '/@/store';
import {
  getBrandsConnectionStart,
  getBrandsConnectionSuccess,
  getBrandsConnectionFailure,
} from './actions';
import type {
  GetBrandsConnectionQueryVariables,
  PageInfo,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getRecommendedBrandsConnectionThunk = (
  after: PageInfo['endCursor'] | null = null,
): AppThunk<Promise<void>> =>
  getBrandsConnectionThunk(
    {
      first: 50,
      after,
      recommendEq: true,
    },
    BRANDS_PATTERN.RECOMMENDED,
  );

const getNonRecommendedBrandsConnectionThunk = (
  after: PageInfo['endCursor'] | null = null,
): AppThunk<Promise<void>> =>
  getBrandsConnectionThunk(
    {
      after,
      recommendEq: false,
    },
    BRANDS_PATTERN.NON_RECOMMENDED,
  );

const getExchangeableBrandsConnectionThunk =
  (after: PageInfo['endCursor'] | null = null): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const myPoints = getState().app?.pointWallet?.point ?? 0;
    return dispatch(
      getBrandsConnectionThunk(
        {
          after,
          withPointsLteq: myPoints,
        },
        BRANDS_PATTERN.EXCHANGEABLE,
      ),
    );
  };

const getBrandsConnectionThunk =
  (
    variables: GetBrandsConnectionQueryVariables,
    brandsPattern: BrandsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getBrandsConnectionStart(brandsPattern));

    return getBrandsConnection(variables)
      .then((response) =>
        dispatch(getBrandsConnectionSuccessThunk(response, brandsPattern)),
      )
      .catch((error) =>
        dispatch(getBrandsConnectionFailureThunk(error, brandsPattern)),
      );
  };

const getBrandsConnectionSuccessThunk =
  (
    response: GetBrandsConnectionResponse,
    brandsPattern: BrandsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { brands } = response.data!;
    dispatch(getBrandsConnectionSuccess(brands, brandsPattern));
    return Promise.resolve();
  };

const getBrandsConnectionFailureThunk =
  (
    error: ApiError,
    brandsPattern: BrandsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getBrandsConnectionFailure(error, brandsPattern));
    return dispatch(handleErrorThunk(error));
  };

const BRANDS_PATTERN = {
  RECOMMENDED: 'RECOMMENDED',
  NON_RECOMMENDED: 'NON_RECOMMENDED',
  EXCHANGEABLE: 'EXCHANGEABLE',
};
type BrandsPatternTypes = (typeof BRANDS_PATTERN)[keyof typeof BRANDS_PATTERN];

export type { BrandsPatternTypes };

export {
  BRANDS_PATTERN,
  getBrandsConnectionThunk,
  getRecommendedBrandsConnectionThunk,
  getNonRecommendedBrandsConnectionThunk,
  getExchangeableBrandsConnectionThunk,
};
