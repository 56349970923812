import { combineReducers } from 'redux';
import { currentUser } from './currentUser';
import { currentSession } from './currentSession';
import { userAuthenticators } from './userAuthenticators';
import { promotionSpots } from './promotionSpots';
import { selectedGifteeContent } from './selectedGifteeContent';
import {
  expiringMyGiftsConnection,
  expiringMyGiftsConnectionActions,
} from './expiringMyGiftsConnection';
import { selectedMyGifts } from './selectedMyGifts';
import {
  availableMyGiftsConnection,
  availableMyGiftsConnectionActions,
} from './availableMyGiftsConnection';
import {
  unavailableMyGiftsConnection,
  unavailableMyGiftsConnectionActions,
} from './unavailableMyGiftsConnection';
import {
  exchangeableBrandsConnection,
  exchangeableBrandsConnectionActions,
} from './exchangeableBrandsConnection';
import { nonRecommendedBrandsConnection } from './nonRecommendedBrandsConnection';
import {
  searchedItemsConnection,
  searchedItemsConnectionActions,
} from './searchedItemsConnection';
import { recommendedBrandsConnection } from './recommendedBrandsConnection';
import { pointWallet } from './pointWallet';
import { pageError } from './pageError';
import { changePageActions } from './changePage';
import {
  itemSearchCondition,
  itemSearchConditionActions,
} from './itemSearchCondition';
import { itemDetail, itemDetailActions } from './itemDetail';
import {
  createPointExchangeInput,
  createPointExchangeInputActions,
} from './createPointExchangeInput';
import { exchangedGifts } from './exchangedGifts';
import { myGift } from './myGift';
import {
  pointConvertableMyGiftsConnection,
  pointConvertableMyGiftsConnectionActions,
} from './pointConvertableMyGiftsConnection';
import { brand, brandActions } from './brand';
import {
  displayOnlyExchangeableBrands,
  displayOnlyExchangeableBrandsActions,
} from './displayOnlyExchangeableBrands';
import { chargedPoint } from './chargedPoint';
import {
  pointChargeWithSerialCodeError,
  pointChargeWithSerialCodeErrorActions,
} from './pointChargeWithSerialCodeError';

export const appReducer = combineReducers({
  currentUser,
  currentSession,
  userAuthenticators,
  promotionSpots,
  selectedGifteeContent,
  expiringMyGiftsConnection,
  availableMyGiftsConnection,
  unavailableMyGiftsConnection,
  exchangeableBrandsConnection,
  nonRecommendedBrandsConnection,
  searchedItemsConnection,
  recommendedBrandsConnection,
  itemSearchCondition,
  pointWallet,
  pageError,
  selectedMyGifts,
  itemDetail,
  createPointExchangeInput,
  exchangedGifts,
  myGift,
  pointConvertableMyGiftsConnection,
  brand,
  displayOnlyExchangeableBrands,
  chargedPoint,
  pointChargeWithSerialCodeError,
});

export const appActions = {
  ...changePageActions,
  ...searchedItemsConnectionActions,
  ...itemSearchConditionActions,
  ...itemDetailActions,
  ...createPointExchangeInputActions,
  ...brandActions,
  ...displayOnlyExchangeableBrandsActions,
  ...pointChargeWithSerialCodeErrorActions,
  ...pointConvertableMyGiftsConnectionActions,
  ...availableMyGiftsConnectionActions,
  ...expiringMyGiftsConnectionActions,
  ...unavailableMyGiftsConnectionActions,
  ...exchangeableBrandsConnectionActions,
};
