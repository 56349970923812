import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import exchangeImage from '/@/assets/signin-about-gift-wallet.png';
import exchangeImageForSerialCodeLP from '/@/assets/serial-code-about-gift-wallet.png';
import { LpTypes, LP_TYPES } from '../types';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 2),
}));
const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: '1',
  marginBottom: theme.spacing(0.5),
  textAlign: 'center',
  whiteSpace: 'pre-line',
}));
const StyledMainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '1.375rem',
  fontWeight: 'bold',
  lineHeight: '1.6',
  textAlign: 'center',
}));
const StyledMainText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.4',
  textAlign: 'left',
  marginTop: theme.spacing(2),
}));
const StyledExchangeImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(3),
}));

type Props = {
  children?: React.ReactNode;
  lpType: LpTypes;
};
const Presenter: React.VFC<Props> = ({ children, lpType }) => (
  <StyledContainer data-cy="signInPageAboutSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow">
        <StyledSubTitle variant="h3">
          {
            lpType === LP_TYPES.LP_CHARGE_SERIAL_CODE
              ? 'もっとお得な使い方\nえらべるPayをポイントに移行すると' : 'ポイント移行をすると'
          }
        </StyledSubTitle>
        <StyledMainTitle variant="h1">まとめてポイントが使える</StyledMainTitle>
      </Grid>
      <Grid size="grow">
        <StyledMainText variant="body1" align="center">
          ポイントを移行すると、移行したポイントに加えてgift wallet
          に貯蓄済みのポイントも合わせて利用することができます。
        </StyledMainText>
      </Grid>
      <Grid size="grow">
        <StyledExchangeImage
          src={
            lpType === LP_TYPES.LP_CHARGE_SERIAL_CODE
              ? exchangeImageForSerialCodeLP : exchangeImage
          }
          alt="exchangeImage"
        />
      </Grid>
      {children && <Grid size="grow">{children}</Grid>}
    </Grid>
  </StyledContainer>
);

export { Presenter };
