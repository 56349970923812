import React from 'react';
import {
  Button,
  ButtonProps,
  Card,
  Grid2 as Grid,
  Typography,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { CreatePointExchangeMutation } from '/@/api/graphql/internalApi/types';
import pointBackIcon from '/@/assets/point_back_icon.svg';
import { Points } from '/@/components/shared/points/Index';

const StyledMyGiftCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  borderRadius: theme.spacing(2),
}));
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  marginTop: 'auto',
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
  border: '1px solid ' + theme.palette.grey.A200,
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: '-webkit-fill-available',
}));
const StyledBrandName = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: '0.625rem',
  lineHeight: '1',
  color: theme.palette.grey.A700,
}));
const StyledItemName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
  fontWeight: 'bold',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledPointBackContent = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 2),
}));
const StyledPointBackIcon = styled('img')({
  marginTop: 'auto',
  marginBottom: 'auto',
});
const StyledPointBackRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
const StyledPointBackTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: theme.palette.grey.A700,
}));
const StyledPointText = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'inline-flex',
}));
const StyledPointBackText = styled(Typography)(({ theme }) => ({
  marginTop: 'auto',
  marginLeft: theme.spacing(0.5),
  fontSize: '0.625rem',
  lineHeight: '1rem',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledUsingGiftButton = styled(Button)<StyledButtonProps>(
  ({ theme }) => ({
    width: '100%',
    fontWeight: 'bold',
    lineHeight: '1',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(4),
  }),
);

type Props = {
  exchangedGifts: CreatePointExchangeMutation['createPointExchange']['pointExchange'];
};
const Presenter: React.VFC<Props> = ({ exchangedGifts }) => (
  <StyledMyGiftCard variant="outlined">
    {exchangedGifts?.myGifts.map((myGift, index) => (
      <div key={index}>
        {index > 0 && <StyledDivider />}
        <StyledLogoAndTitle>
          <StyledLogoContainer>
            <StyledLogo src={myGift.imageUrl} alt={myGift.contentName} />
          </StyledLogoContainer>
          <StyledRightContent>
            <StyledBrandName>{exchangedGifts.item.brand.name}</StyledBrandName>
            <StyledItemName>{myGift.contentName}</StyledItemName>
          </StyledRightContent>
        </StyledLogoAndTitle>
      </div>
    ))}
    {exchangedGifts?.pointBackCampaignApply && (
      <StyledPointBackContent
        container
        direction="row"
        alignContent="flex-start"
      >
        <StyledPointBackIcon
          src={pointBackIcon}
          alt="pointBackIcon"
          width="40"
          height="40"
          data-cy="pointBackIcon"
        />
        <StyledPointBackRightContent>
          <StyledPointBackTitle>獲得した還元ポイント</StyledPointBackTitle>
          <StyledPointText>
            <Points points={exchangedGifts.pointBackCampaignApply.backPoint} />
            <StyledPointBackText>
              {`(${exchangedGifts.pointBackCampaignApply.pointBackCampaign.discountPercentage}%還元)`}
            </StyledPointBackText>
          </StyledPointText>
        </StyledPointBackRightContent>
      </StyledPointBackContent>
    )}
    <Grid container direction="column" alignContent="center">
      {exchangedGifts?.myGifts[0].giftUrl && (
        <StyledUsingGiftButton
          variant="contained"
          color="primary"
          disableElevation
          component={Link}
          to={exchangedGifts.myGifts[0].giftUrl}
          data-cy="usingGiftButton"
        >
          ギフトを使用する
        </StyledUsingGiftButton>
      )}
    </Grid>
  </StyledMyGiftCard>
);

export { Presenter };
