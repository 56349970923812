import React, { useEffect, useState } from 'react';
import { AlertProps, Collapse, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Alert } from '@mui/material';
import {
  AlertMessageState,
  SEVERITY,
  Severity,
} from '/@/store/ui/alertMessage';
import successIcon from '/@/assets/alert_message_success_icon.svg';
import errorIcon from '/@/assets/alert_message_error_icon.svg';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { Link } from 'react-router-dom';

type StyledAlertProps = AlertProps & { isDisplay: boolean; severity: Severity };
const StyledAlert = styled(Alert)<StyledAlertProps>(
  ({ isDisplay, severity, theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    fontWeight: 'bold',
    borderRadius: theme.spacing(1),
    transition: 'opacity 0.5s linear',
    textAlign: 'left',
    ...(isDisplay ? { opacity: 1 } : { opacity: 0 }),
    ...(severity === SEVERITY.SUCCESS && {
      width: `calc(100% - ${theme.spacing(8)})`,
      position: 'fixed',
      top: `calc(${HEADER_HEIGHT}px + ${theme.spacing(2)})`, // ヘッダーの高さ+余白
      left: theme.spacing(2),
      margin: 0,
      color: theme.palette.grey[700],
      border: '1px solid ' + theme.palette.success.main,
      backgroundColor: theme.palette.success.light,
      zIndex: 1000,
    }),
    ...(severity === SEVERITY.ERROR && {
      color: theme.palette.error.light,
      border: '1px solid #EB2300',
      backgroundColor: '#FDF3F2',
    }),
  }),
);
const StyledMessage = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '0.875rem',
  lineHeight: '1.2',
});
const StyledLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  marginTop: theme.spacing(1),
}));
const StyledLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  lineHeight: '1.2',
}));

type Props = {
  alertMessage: NonNullable<AlertMessageState>;
  handleInit: () => void;
};
const Presenter: React.VFC<Props> = ({ alertMessage, handleInit }) => {
  const [isDisplay, setIsDisplay] = useState(true);

  useEffect(() => {
    // success時の表示時間
    if (alertMessage.severity === SEVERITY.SUCCESS) {
      const timer = setTimeout(() => {
        setIsDisplay(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertMessage.severity]);

  const icon = { src: '', alt: '' };

  switch (alertMessage.severity) {
    case SEVERITY.SUCCESS:
      icon.src = successIcon;
      icon.alt = 'successIcon';
      break;
    case SEVERITY.ERROR:
      icon.src = errorIcon;
      icon.alt = 'errorIcon';
      break;
    default:
      break;
  }

  return (
    <Collapse in={isDisplay}>
      <StyledAlert
        icon={<img src={icon.src} alt={icon.alt} width="20" height="20" />}
        severity={alertMessage.severity}
        isDisplay={isDisplay}
        data-cy="alertMessage"
      >
        <StyledMessage variant="body2">{alertMessage.message}</StyledMessage>
        {alertMessage.link?.path && alertMessage.link?.text && (
          <StyledLink
            to={alertMessage.link.path}
            onClick={handleInit}
            data-gtm-click="shared-alertMessage-link"
          >
            <StyledLinkText variant="body2">
              {alertMessage.link.text}
            </StyledLinkText>
          </StyledLink>
        )}
      </StyledAlert>
    </Collapse>
  );
};

export { Presenter };
