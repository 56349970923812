import { Reducer } from 'redux';
import { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';
import {
  MYGIFTS_PATTERN,
  getMyGiftsConnectionTypes,
} from '../../api/getMyGiftsConnection';
import { types } from './types';
import { MyGiftsPatternTypes } from '../../api/getMyGiftsConnection/operations';
import { STATUS, Status } from '../../api/constants';

type ExpiringMyGiftsState =
  GetMyGiftsConnectionQuery['myGiftsConnection']['nodes'];
type PageInfoState =
  | GetMyGiftsConnectionQuery['myGiftsConnection']['pageInfo']
  | null;
type ExpiringMyGiftsConnectionState = {
  myGifts: ExpiringMyGiftsState;
  status: Status;
  pageInfo: PageInfoState;
};

const initialState: ExpiringMyGiftsState = null;
const pageInfoInitialState: PageInfoState = null;

const myGifts: Reducer<ExpiringMyGiftsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getMyGiftsConnectionTypes.GET_MY_GIFTS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      }
      if (!action.myGiftsConnection?.nodes) {
        return state;
      }

      if (state === null) {
        return action.myGiftsConnection.nodes;
      } else {
        const uniqueMyGifts = Array.from(
          new Map(
            [...state, ...action.myGiftsConnection.nodes].map((myGift) => [
              myGift?.urlCode,
              myGift,
            ]),
          ).values(),
        );
        return uniqueMyGifts;
      }
    }
    case types.INITIALISE_EXPIRING_MYGIFTS_CONNECTION:
      return initialState;
    default:
      return state;
  }
};

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case getMyGiftsConnectionTypes.GET_MY_GIFTS_CONNECTION_START: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.LOADING;
      }
    }
    case getMyGiftsConnectionTypes.GET_MY_GIFTS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.SUCCESS;
      }
    }
    case getMyGiftsConnectionTypes.GET_MY_GIFTS_CONNECTION_FAILURE: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.FAILURE;
      }
    }
    default:
      return state;
  }
};

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getMyGiftsConnectionTypes.GET_MY_GIFTS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      }
      return action.myGiftsConnection.pageInfo;
    }
    case types.INITIALISE_EXPIRING_MYGIFTS_CONNECTION:
      return pageInfoInitialState;
    default:
      return state;
  }
};

const expiringMyGiftsConnection = combineReducers({
  myGifts,
  status,
  pageInfo,
});

const isMatchedPattern = (pattern: MyGiftsPatternTypes): boolean =>
  pattern === MYGIFTS_PATTERN.EXPIRING;

export { expiringMyGiftsConnection };
export type { ExpiringMyGiftsConnectionState, ExpiringMyGiftsState };
