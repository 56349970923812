import React, { useCallback, useEffect, useRef } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getSearchedItemsConnectionThunk } from '/@/store/api/getItemsConnection';
import { STATUS } from '/@/store/api/constants';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { getItemDetailThunk } from '/@/store/api/getItemDetail';
import { initialiseSearchedItemsConnection } from '/@/store/app/searchedItemsConnection/actions';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);
  const searchedItems = useAppSelector(
    (state) => state.app.searchedItemsConnection.items,
  );
  const itemSearchCondition = useAppSelector(
    (state) => state.app.itemSearchCondition,
  );
  const pageInfo = useAppSelector(
    (state) => state.app.searchedItemsConnection.pageInfo,
  );
  const getSearchedItemsConnectionStatus = useAppSelector(
    (state) => state.app.searchedItemsConnection.status,
  );
  const selectItemDetail = (uid: string) =>
    dispatch(getItemDetailThunk({ uid: uid })).catch(() => {});

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  const initialiseAndGetSearchedItemsConnection = useCallback(async () => {
    await dispatch(initialiseSearchedItemsConnection());
    await dispatch(getSearchedItemsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (itemSearchCondition) {
      initialiseAndGetSearchedItemsConnection();
    }
  }, [dispatch, itemSearchCondition, initialiseAndGetSearchedItemsConnection]);

  useEffect(() => {
    if (
      isIntersect &&
      getSearchedItemsConnectionStatus !== STATUS.LOADING &&
      pageInfo?.hasNextPage
    ) {
      dispatch(getSearchedItemsConnectionThunk(pageInfo?.endCursor)).catch(
        () => {},
      );
    }
  }, [isIntersect, getSearchedItemsConnectionStatus, pageInfo, dispatch]);

  return (
    <Presenter
      searchedItemContents={searchedItems}
      infiniteScrollTarget={infiniteScrollTarget}
      getSearchedItemsConnectionStatus={getSearchedItemsConnectionStatus}
      selectItemDetail={selectItemDetail}
    />
  );
};

export { Container as PointItemContents };
