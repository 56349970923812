import React from 'react';
import {
  Typography,
  Grid2 as Grid,
  Button,
  ButtonProps,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';
import { Promotions } from './promotions/Index';
import { GifteeContent } from './gifteeContent/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import savedGiftIcon from '/@/assets/saved_gift_icon.svg';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '/@/store/api/saveGifteeContent/selectors';

const StyledPromotionsContainer = styled(Container)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));
const StyledGifteeContentTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  padding: theme.spacing(3),
}));
const StyledDuplicatedGifteeContentErrorMessage = styled(Typography)(
  ({ theme }) => ({
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.warning.main,
    padding: theme.spacing(3),
  }),
);
const StyledGifteeContentContainer = styled(Container)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));
const StyledGifteeContent = styled('div')({
  minWidth: '100%',
  maxWidth: '100%',
});
const StyledSavedGiftIcon = styled('img')({
  margin: 'auto',
});
type StyledButtonProps = ButtonProps & { to: string };
const StyledHomePageButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.spacing(4),
}));
const StyledGifteeContentBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));

type Props = { duplicatedGifteeContentErrorMessage: ErrorMessage };
const Presenter: React.VFC<Props> = ({
  duplicatedGifteeContentErrorMessage,
}) => (
  <MainLayout>
    <StyledGifteeContentBackground>
      <StyledGifteeContentContainer maxWidth="sm">
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
        >
          <StyledSavedGiftIcon
            src={savedGiftIcon}
            alt="ギフトを保存しました"
            width="100"
            height="100"
            data-cy="savedGiftIcon"
          />
          {duplicatedGifteeContentErrorMessage ? (
            <StyledDuplicatedGifteeContentErrorMessage>
              {duplicatedGifteeContentErrorMessage}
            </StyledDuplicatedGifteeContentErrorMessage>
          ) : (
            <StyledGifteeContentTitle>
              ギフトを保存しました
            </StyledGifteeContentTitle>
          )}
          <StyledGifteeContent>
            <GifteeContent />
          </StyledGifteeContent>
          <StyledHomePageButton
            variant="contained"
            color="primary"
            disableElevation
            component={Link}
            to={PATHS.HOME}
            data-cy="homePageButton"
          >
            マイギフトを確認する
          </StyledHomePageButton>
        </Grid>
      </StyledGifteeContentContainer>
    </StyledGifteeContentBackground>
    <StyledPromotionsContainer maxWidth="sm">
      <Promotions />
    </StyledPromotionsContainer>
  </MainLayout>
);

export { Presenter };
