import React, { useEffect, useMemo, useState } from 'react';
import { Presenter } from './Presenter';
import { useLocation, useNavigate } from 'react-router-dom';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';
import { useAuth, AUTHENTICATION_STATUS } from '/@/common/hooks/useAuth';
import { decideGoForwardLocation } from '/@/utils/decideLocationAfterOauth';
import { useAppDispatch, useAppSelector } from '/@/store';
import { selectLineUnfriendedErrorMessage } from '/@/store/api/saveGifteeContent/selectors';
import { createOauthSessionOption } from '/@/utils/createOauthSessionOption';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { search } = location;
  const errorMessage = location.state?.errorMessage;

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const url = urlSearchParams.get('gift_url');
  const origin = urlSearchParams.get('gift_origin') as OriginEnum | null;
  const convertToPoint = urlSearchParams.get('convert_to_point') === 'true';
  const destination = urlSearchParams.get(
    'destination',
  ) as DestinationEnum | null;
  const serialCode = urlSearchParams.get('serial_code');
  const serialCodeEndAt = urlSearchParams.get('end_at');
  const authenticationStatus = useAuth();
  const navigate = useNavigate();

  const lineUnfriendedErrorMessage = useAppSelector(
    selectLineUnfriendedErrorMessage,
  );
  const [isSerialCodeOutOfPeriod, setIsSerialCodeOutOfPeriod] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED &&
      !lineUnfriendedErrorMessage &&
      !errorMessage &&
      !serialCode
    ) {
      const { giftSavingOption, destinationOption } =
        createOauthSessionOption(urlSearchParams);

      navigate(
        decideGoForwardLocation({
          giftSavingOption,
          destinationOption,
        }),
        { replace: true },
      );
    }
  }, [
    authenticationStatus,
    lineUnfriendedErrorMessage,
    errorMessage,
    serialCode,
    urlSearchParams,
    navigate,
  ]);

  useEffect(() => {
    if (serialCodeEndAt) {
      // クエリパラメータ取得時に+がスペースに変換されてしまうため、元に戻す
      const endAt = new Date(serialCodeEndAt.replace(' ', '+')).getTime();
      const now = new Date().getTime();

      if (endAt < now) {
        setIsSerialCodeOutOfPeriod(true);
      }
    }
  }, [dispatch, serialCodeEndAt]);

  return (
    <Presenter
      url={url}
      origin={origin}
      referer={null}
      convertToPoint={convertToPoint}
      destination={destination}
      serialCode={serialCode}
      isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
      errorMessage={errorMessage}
    />
  );
};

export { Container as SignInPage };
