import { Reducer } from 'redux';
import {
  GetItemsConnectionQuery,
  ItemConnection,
} from '/@/api/graphql/internalApi/types';
import {
  ITEMS_PATTERN,
  getItemsConnectionTypes,
} from '/@/store/api/getItemsConnection/index';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';
import { initialiseSearchedItemsConnectionTypes } from './index';
import { STATUS, Status } from '/@/store/api/constants';
import { ItemsPatternTypes } from '/@/store/api/getItemsConnection/operations';
import { ItemBase } from '/@/store/app/shared/types';

type SearchedItemsState = Item[] | null;
type PageInfoState = ItemConnection['pageInfo'] | null;
type SearchedItemsConnectionState = {
  items: SearchedItemsState;
  status: Status;
  pageInfo: PageInfoState;
};

type Item = ItemBase;

const initialState: SearchedItemsState = null;
const pageInfoInitialState: PageInfoState = null;

const items: Reducer<SearchedItemsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      }
      if (!action.itemsConnection?.nodes) {
        return state;
      }
      const filteredNodes = filterNodes(action.itemsConnection.nodes);

      if (state === null) {
        return filteredNodes;
      } else {
        const uniqueItems = Array.from(
          new Map(
            [...state, ...filteredNodes].map((item) => [item?.uid, item]),
          ).values(),
        );
        return uniqueItems;
      }
    }
    case initialiseSearchedItemsConnectionTypes.INITIALISE_SEARCHED_ITEMS_CONNECTION:
      return initialState;
    default:
      return state;
  }
};

const filterNodes = (
  itemsConnectionNodes: NonNullable<GetItemsConnectionQuery['items']['nodes']>,
): NonNullable<SearchedItemsState> =>
  itemsConnectionNodes.filter((node) => !!node) as Item[];

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_START: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.LOADING;
      }
    }
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.SUCCESS;
      }
    }
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_FAILURE: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.FAILURE;
      }
    }
    case initialiseSearchedItemsConnectionTypes.INITIALISE_SEARCHED_ITEMS_CONNECTION:
      return STATUS.IDLE;
    default:
      return state;
  }
};

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getItemsConnectionTypes.GET_ITEMS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      }
      return action.itemsConnection.pageInfo;
    }
    case initialiseSearchedItemsConnectionTypes.INITIALISE_SEARCHED_ITEMS_CONNECTION:
      return pageInfoInitialState;
    default:
      return state;
  }
};

const searchedItemsConnection = combineReducers({
  items,
  status,
  pageInfo,
});

const isMatchedPattern = (pattern: ItemsPatternTypes): boolean =>
  pattern === ITEMS_PATTERN.SEARCHED;

export { searchedItemsConnection };
export type { SearchedItemsConnectionState, SearchedItemsState };
