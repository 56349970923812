import React from 'react';
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { STATUS, Status } from '/@/store/api/constants';
import { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';

const StyledMenuListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));
const StyledMenuListItemText = styled(ListItemText)({
  fontSize: '0.875rem',
  fontWeight: 'bold',
});
const StyledNoExchangeableBrandText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.4',
  fontWeight: 'bold',
  margin: theme.spacing(2),
}));

type Props = {
  exchangeableBrands: GetBrandsConnectionQuery['brands']['nodes'];
  selectedUid: string | null;
  setBrandCondition: (uid: string) => void;
  getExchangeableBrandsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  exchangeableBrands,
  selectedUid,
  setBrandCondition,
  getExchangeableBrandsConnectionStatus,
}) => (
  <Box>
    <List
      data-cy="searchBrandList"
      component="nav"
      aria-label="menu-list"
      style={{ paddingTop: 0 }}
    >
      {exchangeableBrands?.map(
        (brand, Index) =>
          brand && (
            <div key={Index}>
              <StyledMenuListItem
                onClick={() => setBrandCondition(brand.uid)}
                data-gtm-click="templates-header-searchHeader-brandCondition-setBrandConditionButton"
              >
                <ListItemIcon>
                  {brand.uid === selectedUid && <CheckIcon />}
                </ListItemIcon>
                <StyledMenuListItemText primary={brand.name} />
              </StyledMenuListItem>
              <Divider component="li" />
            </div>
          ),
      )}
      {exchangeableBrands?.length === 0 &&
        getExchangeableBrandsConnectionStatus !== STATUS.LOADING && (
          <StyledNoExchangeableBrandText>
            選択可能なブランドはありません
          </StyledNoExchangeableBrandText>
        )}
    </List>
  </Box>
);

export { Presenter };
