import React from 'react';
import { Typography, Grid2 as Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import { ExchangedGifts } from './exchangedGifts/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import savedGiftIcon from '/@/assets/saved_gift_icon.svg';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import { PointLogType_StatusEnumType } from '/@/api/graphql/internalApi/types';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const StyledExchangedGiftsTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  padding: theme.spacing(3),
}));
const StyledExchangedGiftsContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 2),
}));
const StyledExchangedGifts = styled('div')({
  minWidth: '100%',
  maxWidth: '100%',
});
const StyledAlertMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.dark,
  padding: theme.spacing(3),
}));
const StyledSavedGiftIcon = styled('img')({
  margin: 'auto',
});
const StyledExchangedGiftsBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));
const StyledMyGiftsLinkText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '0.675rem',
}));
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));
// const StyledPopulerItemsSection = styled(Container)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

type Props = {
  pointLogStatus?: PointLogType_StatusEnumType;
};

const Presenter: React.VFC<Props> = ({ pointLogStatus }) => (
  <MainLayout>
    <StyledExchangedGiftsBackground>
      <StyledExchangedGiftsContainer maxWidth="sm">
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
        >
          <StyledSavedGiftIcon
            src={savedGiftIcon}
            alt="ギフトを交換しました"
            width="100"
            height="100"
            data-cy="savedGiftIcon"
          />
          <StyledExchangedGiftsTitle>
            {pointLogStatus === PointLogType_StatusEnumType.Retrying
              ? 'ギフト発行中に問題が発生しました'
              : 'ギフトを交換しました'}
          </StyledExchangedGiftsTitle>
          <StyledExchangedGifts>
            {pointLogStatus === PointLogType_StatusEnumType.Retrying ? (
              <StyledAlertMessage>
                ギフト発行中に問題が発生しました。
                <br />
                少し時間をおいてから、マイギフトページより再度実行してください。
              </StyledAlertMessage>
            ) : (
              <ExchangedGifts />
            )}
          </StyledExchangedGifts>
        </Grid>
        <StyledMyGiftsLinkText>
          獲得したギフトは
          <StyledLink to={PATHS.HOME} data-cy="homePageLink">
            マイギフト
          </StyledLink>
          より確認できます
        </StyledMyGiftsLinkText>
      </StyledExchangedGiftsContainer>
    </StyledExchangedGiftsBackground>
    {/* データが貯まるまで一旦非表示. cf: https://giftee-inc.slack.com/archives/C06E76T0J86/p1724049106246419?thread_ts=1723773614.435139&cid=C06E76T0J86 */}
    {/* <StyledPopulerItemsSection maxWidth='sm'>
      <PopulerItemsSection />
    </StyledPopulerItemsSection> */}
    <ItemDetailModal />
  </MainLayout>
);

export { Presenter };
