import { createPointExchange } from '/@/api/graphql/internalApi/createPointExchange';
import type { CreatePointExchangeResponse } from '/@/api/graphql/internalApi/createPointExchange';
import type { AppThunk } from '/@/store';
import {
  createPointExchangeStart,
  createPointExchangeSuccess,
  createPointExchangeFailure,
} from './actions';
import { handleErrorThunk } from '/@/store/api/handleError';
import type { ApiError } from '/@/api/graphql/client';
import {
  CreatePointExchangeInput,
  CreatePointExchangeMutation,
  PointLogType_StatusEnumType,
} from '/@/api/graphql/internalApi/types';
import { setExchangeAlertMessage } from '/@/store/ui/alertMessage/actions';
import { ExchangeErrorPatternType } from '/@/store/ui/alertMessage/constants';
import type { MutationErrors } from '/@/api/graphql/client';
import * as Sentry from '@sentry/browser';

const createPointExchangeThunk =
  (
    input: CreatePointExchangeInput,
  ): AppThunk<Promise<CreatePointExchangeMutation>> =>
  (dispatch) => {
    dispatch(createPointExchangeStart());
    return createPointExchange(input)
      .then((response) => {
        const status =
          response.data!.createPointExchange?.pointExchange?.pointLog?.status;
        if (status === PointLogType_StatusEnumType.Success) {
          dispatch(createPointExchangeSuccessThunk(response));
        } else if (status === PointLogType_StatusEnumType.Failure) {
          // MEMO:強制的に500エラー画面に飛ばす
          dispatch(createPointExchangeFailureThunk({} as ApiError));
        } else {
          // MEMO: その他のパターンはsentryに通知
          const errors = JSON.stringify(
            response.data!.createPointExchange?.errors,
          );
          sendCreatePointExchangeExceptionToSentry(
            `{ status: ${status}, errors: ${errors} }`,
          );
        }
        return response.data!;
      })
      .catch((error) => {
        if (error && Array.isArray(error) && error.length > 0) {
          const mutationErrors = error as MutationErrors;
          const errorWithExtensionsCode = mutationErrors.find(
            (err) => err.extensions?.code !== null,
          );
          if (errorWithExtensionsCode) {
            dispatch(
              setExchangeAlertMessage(
                errorWithExtensionsCode.extensions
                  ?.code as ExchangeErrorPatternType,
              ),
            );
            return errorWithExtensionsCode;
          }
        }

        dispatch(createPointExchangeFailureThunk(error));
        return error;
      });
  };

const createPointExchangeSuccessThunk =
  (response: CreatePointExchangeResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { pointExchange, pointWallet } = response.data!.createPointExchange;
    dispatch(createPointExchangeSuccess(pointExchange, pointWallet));
    return Promise.resolve();
  };

const createPointExchangeFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createPointExchangeFailure(error));
    return dispatch(handleErrorThunk(error));
  };

const sendCreatePointExchangeExceptionToSentry = (message: string) =>
  Sentry.captureException(`Create Point Exchange Exception: ${message}`);

export { createPointExchangeThunk };
