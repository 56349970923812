import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';

type StyledLabelProps = {
  isNotEmphased: boolean;
  isSmall: boolean;
};
const StyledLabel = styled(Chip)<StyledLabelProps>(
  ({ isNotEmphased, isSmall, theme }) => ({
    padding: isSmall ? theme.spacing(0.25, 0.75) : theme.spacing(0.25, 1),
    height: isSmall ? theme.spacing(2) : theme.spacing(2.875),
    backgroundColor: isNotEmphased ? theme.palette.grey.A200 : '#F7E9E8',
    '& .MuiChip-label': {
      padding: '0',
      color: isNotEmphased
        ? theme.palette.secondary.dark
        : theme.palette.error.light,
      fontSize: isSmall ? '0.625rem' : '0.75rem',
      lineHeight: isSmall ? '1' : '1.2',
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  discountPercentage: number;
  isEmphased: boolean;
  labelSize: 'small' | 'medium';
};

const Presenter: React.VFC<Props> = ({
  discountPercentage,
  isEmphased,
  labelSize,
}) => (
  <StyledLabel
    label={`${discountPercentage}%還元`}
    isNotEmphased={!isEmphased}
    isSmall={labelSize === 'small'}
  />
);

export { Presenter };
