import React from 'react';
import { Typography, Button, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { getLineBotFriendUrl } from '/@/common/lineBotFriendUrl';

const StyledMainMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontWeight: 'bold',
}));
const StyledAddFriendButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.spacing(4),
  textTransform: 'none',
  width: '100%',
}));
const StyledSubMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

type Props = {
  message: string;
};

const Presenter: React.VFC<Props> = ({ message }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    direction="column"
    data-cy="friendRequest"
  >
    <StyledMainMessage variant="body2" align="center">
      {message}
    </StyledMainMessage>
    <StyledAddFriendButton
      variant="contained"
      color="primary"
      disableElevation
      href={getLineBotFriendUrl()}
    >
      gift walletを友だち追加する
    </StyledAddFriendButton>
    <StyledSubMessage variant="caption" align="center">
      友だち追加すると、以下のギフトを保存できます
    </StyledSubMessage>
  </Grid>
);

export { Presenter };
