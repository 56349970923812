import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import decoration from '/@/assets/signin-how-to-use-decoration.svg';

const StyledSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, 3),
}));
const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.dark,
}));
const StyledSectionText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.6',
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));
const StyledDetail = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1),
  marginTop: theme.spacing(4),
}));
const StyledDetailTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '1',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledDecorationImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(3),
}));
const StyledDetailDescription = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.dark,
  fontWeight: 'normal',
}));

const Presenter: React.VFC<Record<string, never>> = () => {
  const details = [
    {
      title: 'もらったギフトを一覧で管理できる',
      description:
        '「マイギフト」ページから確認できるので、ギフトの期限切れ、使い忘れを減らせます。',
    },
    {
      title: '保存したチケットをすぐに表示できる',
      description:
        'gift walletのLINEアカウントを開いて、チケットをタップするだけ。',
    },
    {
      title: 'ギフトはLINEから確認できる',
      description:
        'ギフトがgift walletに保存されると、LINEアカウントに通知されます。',
    },
  ];

  return (
    <div data-cy="signInPageAboutSection">
      <StyledSection container direction="row" justifyContent="center">
        <Grid size={{ xs: 12 }}>
          <StyledSectionTitle align="center" variant="h5">
            gift wallet の利用方法
          </StyledSectionTitle>
          <StyledSectionText variant="body1">
            gift wallet は、
            <br />
            せっかくのギフトを使い忘れなく、
            <br />
            有効活用するためのお財布サービスです。
          </StyledSectionText>
          <StyledDecorationImage src={decoration} alt="decoration" />
        </Grid>

        {details.map((detail, index) => (
          <StyledDetail size={{ xs: 12 }} key={index}>
            <StyledDetailTitle variant="body1">
              {detail.title}
            </StyledDetailTitle>
            <StyledDetailDescription variant="caption">
              {detail.description}
            </StyledDetailDescription>
          </StyledDetail>
        ))}
      </StyledSection>
    </div>
  );
};

export { Presenter };
