import React, { useCallback, useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getExchangeableBrandsConnectionThunk } from '/@/store/api/getBrandsConnection';
import { STATUS } from '/@/store/api/constants';
import { ItemSearchConditionState } from '/@/store/app/itemSearchCondition/reducers';
import { initialiseExchangeableBrandsConnection } from '/@/store/app/exchangeableBrandsConnection/actions';

type OwnProps = {
  handleClose: () => void;
  conditions: ItemSearchConditionState;
  setConditions: (conditions: ItemSearchConditionState) => void;
};

const Container: React.VFC<OwnProps> = ({
  handleClose,
  conditions,
  setConditions,
}) => {
  const dispatch = useAppDispatch();
  const exchangeableBrands = useAppSelector(
    (state) => state.app.exchangeableBrandsConnection.brands,
  );
  const point = useAppSelector((state) => state.app.pointWallet?.point);
  const pageInfo = useAppSelector(
    (state) => state.app.exchangeableBrandsConnection.pageInfo,
  );
  const getExchangeableBrandsConnectionStatus = useAppSelector(
    (state) => state.app.exchangeableBrandsConnection.status,
  );

  const setBrandCondition = (uid: string): void => {
    setConditions({
      ...conditions,
      brandUid: uid,
    });
    handleClose();
  };

  const initialiseAndGetExchangeableBrandsConnection = useCallback(async () => {
    await dispatch(initialiseExchangeableBrandsConnection());
    await dispatch(getExchangeableBrandsConnectionThunk());
  }, [dispatch]);

  useEffect(() => {
    if (point !== undefined) {
      initialiseAndGetExchangeableBrandsConnection();
    }
  }, [dispatch, point, initialiseAndGetExchangeableBrandsConnection]);

  useEffect(() => {
    if (
      getExchangeableBrandsConnectionStatus !== STATUS.LOADING &&
      pageInfo?.hasNextPage
    ) {
      dispatch(getExchangeableBrandsConnectionThunk(pageInfo.endCursor)).catch(
        () => {},
      );
    }
  }, [
    dispatch,
    getExchangeableBrandsConnectionStatus,
    exchangeableBrands,
    pageInfo,
    point,
  ]);

  return (
    <Presenter
      exchangeableBrands={exchangeableBrands}
      selectedUid={conditions?.brandUid || null}
      setBrandCondition={setBrandCondition}
      getExchangeableBrandsConnectionStatus={
        getExchangeableBrandsConnectionStatus
      }
    />
  );
};
export { Container as BrandCondition };
