import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ErrorMessage } from '/@/store/api/saveGifteeContent/selectors';
import { SavingGift } from './savingGift/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { FriendRequest } from './friendRequest/Index';

const StyledLoginSectionContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));
const StyledFriendRequestContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));
const StyledAuthenticationErrorMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
  padding: theme.spacing(3),
}));
const StyledSerialCodeOutOfPeriodMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));

type Props = LoginOption & {
  lineUnfriendedErrorMessage: ErrorMessage;
  authenticationErrorMessage: ErrorMessage;
  isSerialCodeOutOfPeriod: boolean;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  lineUnfriendedErrorMessage,
  authenticationErrorMessage,
  isSerialCodeOutOfPeriod,
}) => {
  const buttonText = serialCode
    ? 'ログインしてポイントをチャージ'
    : url && origin
      ? 'ログインしてギフトを保存する'
      : 'ログインする';
  return (
    <div data-cy="signInPageLoginSection">
      {authenticationErrorMessage && (
        <StyledAuthenticationErrorMessage variant="body2">
          {authenticationErrorMessage}
        </StyledAuthenticationErrorMessage>
      )}
      {isSerialCodeOutOfPeriod && (
        <StyledSerialCodeOutOfPeriodMessage variant="body2">
          {`シリアルコードの有効期限が切れています`}
        </StyledSerialCodeOutOfPeriodMessage>
      )}
      {lineUnfriendedErrorMessage && (
        <StyledFriendRequestContainer>
          <FriendRequest message={lineUnfriendedErrorMessage} />
        </StyledFriendRequestContainer>
      )}
      {url && origin && (
        <div>
          <SavingGift url={url} origin={origin} />
        </div>
      )}
      <StyledLoginSectionContainer>
        <LoginButton
          url={null}
          origin={null}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        >
          {buttonText}
        </LoginButton>
      </StyledLoginSectionContainer>
    </div>
  );
};

export { Presenter };
