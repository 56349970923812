import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const StyledCautionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '0.75rem',
  textAlign: 'left',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  paddingLeft: '0.75rem', // ※の位置調整
  textIndent: '-0.75rem', // ※の位置調整
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <>
    <StyledCautionText>
      ※Amazon、Amazon.co.jpおよびそれらのロゴは Amazon.com, Inc.
      またはその関連会社の商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※本プログラムは株式会社ギフティによる提供です。本プログラムについてのお問い合わせは
      Amazonではお受けしておりません。 gift walletにログイン後の「
      <Link to={PATHS.INQUIRIES_NEW}>お問い合わせ</Link>」よりお願いいたします。
    </StyledCautionText>
    <StyledCautionText>
      ※GOおよび、GOチケットのロゴはGO株式会社の商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※本プログラムは株式会社ギフティによる提供です。本プログラムについてのお問い合わせはGOではお受けしておりません。
      gift walletにログイン後の「
      <Link to={PATHS.INQUIRIES_NEW}>お問い合わせ</Link>」よりお願いいたします。
    </StyledCautionText>
    <StyledCautionText>
      ※Coke ONはThe Coca-Cola Companyの登録商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※ © 2024 iTunes K.K. All rights reserved.
    </StyledCautionText>
    <StyledCautionText>
      ※Google Play は Google LLCの商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※「au PAY」は、KDDI株式会社の商標です。
    </StyledCautionText>
    <StyledCautionText>
      ※PayPayポイントは出金、譲渡不可です。PayPay／PayPayカード公式ストアでも利用可能です。
    </StyledCautionText>
  </>
);

export { Presenter };
