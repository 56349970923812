import React from 'react';
import {
  Typography,
  Grid2 as Grid,
  Container,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import errorIcon from '/@/assets/triangle-exclamation.svg';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';

const StyledPointChargeErrorContainer = styled(Container)(({ theme }) => ({
  height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
  backgroundColor: theme.palette.background.hint,
  padding: theme.spacing(3),
}));
const StyledErrorIcon = styled('img')({
  margin: 'auto',
});
const StyledPointChargeErrorTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.error.light,
  marginTop: theme.spacing(3),
}));
const StyledErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  textAlign: 'left',
  marginTop: theme.spacing(3),
}));
const StyledNavigateToHomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  fontWeight: 'bold',
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(4),
}));

type Props = {
  navigateToHome: () => void;
  errorMessage: string;
};
const Presenter: React.VFC<Props> = ({ navigateToHome, errorMessage }) => (
  <MainLayout>
    <StyledPointChargeErrorContainer maxWidth="sm">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <StyledErrorIcon
          src={errorIcon}
          alt="error-icon"
          width="100"
          height="100"
          data-cy="errorIcon"
        />
        <StyledPointChargeErrorTitle>
          ポイント獲得に失敗しました
        </StyledPointChargeErrorTitle>
        <StyledErrorText>{errorMessage}</StyledErrorText>
        <StyledNavigateToHomeButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={navigateToHome}
          data-cy="navigateToHomeButton"
          data-gtm-click="pointPage-chargePage-completionPage-navigateToHomeButton"
        >
          ホームへ戻る
        </StyledNavigateToHomeButton>
      </Grid>
    </StyledPointChargeErrorContainer>
  </MainLayout>
);

export { Presenter };
