import React from 'react';
import {
  AppBar,
  SwipeableDrawer,
  List,
  ListItemText,
  Divider,
  Typography,
  Grid2 as Grid,
  Button,
  IconButton,
  TextField,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import closeIcon from '/@/assets/close-icon.svg';
import arrowRightIcon from '/@/assets/arrow_right_icon.svg';
import sliderIcon from '/@/assets/slider_icon.svg';
import { BrandCondition } from './brandCondition/Index';
import { ConditionOpenedType } from '.';
import { theme } from '/@/utils/theme';
import { ItemSearchConditionState } from '/@/store/app/itemSearchCondition/reducers';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: 'none',
}));
const StyledTitleGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  height: `${HEADER_HEIGHT}px`,
  minHeight: `${HEADER_HEIGHT}px`,
}));
const StyledArrowBackIcon = styled(IconButton)({
  padding: 0,
});
const StyledTitle = styled(Typography)({
  fontWeight: 'bold',
});
const StyledSearchMenuDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    height: '100%',
    maxWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '87.5%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
}));
const StyledSearchMenuHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));
const StyledSearchMenuTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 'bold',
  textAlign: 'center',
}));
const StyledBackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(2),
  top: theme.spacing(1),
  color: theme.palette.primary.main,
  minWidth: 'auto',
  minHeight: 'auto',
}));
const StyledClearButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(1),
  color: theme.palette.primary.main,
  minWidth: 'auto',
  minHeight: 'auto',
}));
const StyledCloseIcon = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(-2),
  top: theme.spacing(2),
  height: '20px',
  width: '20px',
  transform: 'translateX(-50%)',
}));
const StyledMenuListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));
const StyledPointRangeInputs = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
const StyledMenuListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
});
const StyledSearchButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  width: '90%',
  margin: 'auto',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(4),
}));
const StyledPointInputField = styled(TextField)({
  width: '4rem',
});

type Props = {
  headerTitle: string;
  sideMenuTitle: string;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  isConditionOpened: ConditionOpenedType;
  handleOpenCondition: (type: ConditionOpenedType) => void;
  handleNavigateBack: () => void;
  selectedBrandName: string | null;
  conditions: ItemSearchConditionState;
  setConditions: (conditions: ItemSearchConditionState) => void;
  searchItems: () => void;
  handleClearBrandCondition: () => void;
  handleClearCondition: () => void;
  setMinPointCondition: (minPoint: number) => void;
  setMaxPointCondition: (maxPoint: number) => void;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  sideMenuTitle,
  isMenuOpened,
  handleToggleMenu,
  isConditionOpened,
  handleOpenCondition,
  handleNavigateBack,
  selectedBrandName,
  conditions,
  setConditions,
  searchItems,
  handleClearBrandCondition,
  handleClearCondition,
  setMinPointCondition,
  setMaxPointCondition,
}) => (
  <>
    <StyledAppBar
      position="fixed"
      variant="outlined"
      color="default"
      data-cy="header"
    >
      <StyledTitleGrid container alignItems="center">
        <StyledArrowBackIcon
          color="secondary"
          onClick={handleNavigateBack}
          data-gtm-click="templates-header-seachHeader-handleNavigateBackButton"
        >
          <ArrowBackIosIcon />
        </StyledArrowBackIcon>
        <StyledTitle>{headerTitle}</StyledTitle>
        <Button
          onClick={handleToggleMenu}
          style={{ minWidth: 'auto', padding: 0 }}
          data-gtm-click="templates-header-seachHeader-handleToggleMenuButton"
        >
          <img
            src={sliderIcon}
            alt="絞り込みメニュー"
            width="20"
            height="20"
            data-cy="sliderIcon"
          />
        </Button>
      </StyledTitleGrid>
    </StyledAppBar>

    <StyledSearchMenuDrawer
      anchor="right"
      open={isMenuOpened}
      onOpen={() => {}} // Do nothing
      onClose={handleToggleMenu}
    >
      <StyledCloseIcon src={closeIcon} alt="閉じるボタン" data-cy="closeIcon" />
      <StyledSearchMenuHeader>
        {isConditionOpened === 'brand' && (
          <StyledBackButton
            onClick={() => handleOpenCondition(null)}
            data-gtm-click="templates-header-searchHeader-handleCloseBrandConditionButton"
          >
            <ArrowBackIosIcon />
          </StyledBackButton>
        )}
        <StyledSearchMenuTitle>{sideMenuTitle}</StyledSearchMenuTitle>
        <StyledClearButton
          onClick={
            isConditionOpened !== 'brand'
              ? handleClearCondition
              : handleClearBrandCondition
          }
          data-gtm-click={`templates-header-searchHeader-${isConditionOpened !== 'brand' ? 'handleClearCondition' : 'handleClearBrandCondition'}Button`}
        >
          クリア
        </StyledClearButton>
      </StyledSearchMenuHeader>
      {isConditionOpened === 'brand' ? (
        <BrandCondition
          handleClose={() => handleOpenCondition(null)}
          conditions={conditions}
          setConditions={setConditions}
        />
      ) : (
        <>
          <List
            data-cy="menuList"
            component="nav"
            aria-label="menu-list"
            style={{ paddingTop: 0 }}
          >
            <StyledMenuListItem
              onClick={() => handleOpenCondition('brand')}
              data-gtm-click="templates-header-searchHeader-handleOpenBrandConditionButton"
            >
              <StyledMenuListItemText primary="ブランド" />
              <div
                style={{
                  color: selectedBrandName ? 'black' : theme.palette.grey.A400,
                  fontSize: '0.875rem',
                }}
              >
                {selectedBrandName ?? '指定なし'}
                <img
                  src={arrowRightIcon}
                  alt="条件選択ボタン"
                  width="24"
                  height="12"
                  data-cy="arrowRightIcon"
                />
              </div>
            </StyledMenuListItem>
            <Divider component="li" />
            <StyledMenuListItem onClick={() => handleOpenCondition('point')}>
              <StyledMenuListItemText primary="ポイント" />
              <StyledPointRangeInputs>
                <StyledPointInputField
                  type="number"
                  placeholder="100"
                  variant="standard"
                  value={
                    conditions?.minPoint && conditions.minPoint > 0
                      ? conditions.minPoint
                      : ''
                  }
                  onChange={(e) => {
                    if (typeof Number(e.target.value) == 'number')
                      setMinPointCondition(Number(e.target.value));
                  }}
                  slotProps={{
                    input: {
                      inputProps: {
                        min: 0,
                        max: 999999,
                        style: { textAlign: 'right' },
                      },
                    },
                  }}
                />
                <Typography
                  style={{ display: 'inline-block', margin: 'auto 30px' }}
                >
                  {'-'}
                </Typography>
                <StyledPointInputField
                  type="number"
                  placeholder="10000"
                  variant="standard"
                  value={
                    conditions?.maxPoint && conditions.maxPoint > 0
                      ? conditions.maxPoint
                      : ''
                  }
                  onChange={(e) => {
                    if (typeof Number(e.target.value) == 'number')
                      setMaxPointCondition(Number(e.target.value));
                  }}
                  slotProps={{
                    input: {
                      inputProps: {
                        min: 0,
                        max: 999999,
                        style: { textAlign: 'right' },
                      },
                    },
                  }}
                />
              </StyledPointRangeInputs>
            </StyledMenuListItem>
          </List>
          <StyledSearchButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={searchItems}
            disabled={
              !!(
                conditions?.minPoint &&
                conditions?.maxPoint &&
                conditions?.minPoint > conditions?.maxPoint
              )
            }
            data-gtm-click="templates-header-searchHeader-searchButton"
          >
            検索
          </StyledSearchButton>
        </>
      )}
    </StyledSearchMenuDrawer>
  </>
);

export { Presenter };
