import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import scrollDown from '/@/assets/signin-about-scroll-down.svg';
import decoration from '/@/assets/signin-about-decoration.svg';

const StyledScrollDownImage = styled('img')(() => ({
  display: 'block',
  margin: 'auto',
}));
const StyledDecorationImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '85%',
  marginTop: theme.spacing(4),
}));
const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  marginTop: theme.spacing(4),
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <div data-cy="signInPageAboutSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow">
        <StyledScrollDownImage src={scrollDown} alt="scroll down" />
      </Grid>
      <Grid size="grow">
        <StyledDescription variant="body2" align="center">
          gift walletは、 <br />
          せっかくのギフトを使い忘れなく、
          <br />
          しっかり活用するためのお財布サービスです。
        </StyledDescription>
      </Grid>
      <Grid size="grow">
        <StyledDecorationImage src={decoration} alt="decoration" />
      </Grid>
    </Grid>
  </div>
);

export { Presenter };
