import React, { ChangeEvent, FormEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Container,
  SelectChangeEvent,
  ButtonProps,
  FormLabelProps,
} from '@mui/material';
import { styled } from '@mui/system';

import { Header } from '/@/components/templates/header/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { PATHS } from '/@/routes/paths';
import createUserProfileIcon from '/@/assets/create_user_profile_icon.svg';
import { genderCodes } from '/@/utils/genderCodeMapping';
import { prefectureCodes } from '/@/utils/prefectureCodeMapping';
import { UserProfileNewPageInputState } from '/@/store/page/userPage/profilePage/newPage';

const StyledBaseBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginTop: `calc(${HEADER_HEIGHT}px + ${theme.spacing(5)})`,
  marginBottom: theme.spacing(5),
}));
const StyledCreateUserProfileIcon = styled('img')({
  display: 'block',
  margin: 'auto',
});
const StyledTitleText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledCaptionText = styled(Typography)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  color: theme.palette.secondary.main,
}));
const StyledDateText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
}));
const StyledFormContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledFormGroup = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(),
}));
const StyledGenderLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledBirthdayLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledPrefectureLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
type StyledGenderSelectionButtonProps = ButtonProps & { isSelected: boolean };
const StyledGenderSelectionButton = styled(
  Button,
)<StyledGenderSelectionButtonProps>(({ isSelected, theme }) => ({
  ...(isSelected
    ? {
        fontWeight: 'bold',
        color: theme.palette.background.highContrast,
        backgroundColor: theme.palette.background.contrast,
      }
    : {
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
      }),
}));
const StyledSubmitButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(2),
  fontWeight: 'bold',
}));

type Props = {
  genderCode: UserProfileNewPageInputState['genderCode'] | null;
  prefectureCode: UserProfileNewPageInputState['prefectureCode'] | null;
  isLoading: boolean;
  isReadyToSubmit: boolean;
  pushDatalayer: () => void;
  handleChangeGender: (selectedGenderCode: number) => void;
  handleChangeBirthdayYear: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayMonth: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthdayDay: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangePrefecture: (event: SelectChangeEvent<number | null>) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const Presenter: React.VFC<Props> = ({
  genderCode,
  prefectureCode,
  isLoading,
  isReadyToSubmit,
  pushDatalayer,
  handleChangeGender,
  handleChangeBirthdayYear,
  handleChangeBirthdayMonth,
  handleChangeBirthdayDay,
  handleChangePrefecture,
  handleSubmit,
}) => (
  <>
    <Header isMenuVisible={false} isLogoClickable={false} />
    <Container maxWidth="sm">
      <StyledBaseBox>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid size="grow">
            <StyledCreateUserProfileIcon
              data-cy="createUserProfileIcon"
              alt="プロフィール作成アイコン"
              src={createUserProfileIcon}
            />
          </Grid>
          <Grid size="grow">
            <Grid container direction="column" alignItems="stretch" spacing={1}>
              <Grid size="grow">
                <StyledTitleText variant="subtitle1" gutterBottom>
                  プロフィールを登録してギフトを保存
                </StyledTitleText>
              </Grid>
              <Grid size="grow">
                <StyledCaptionText variant="caption" gutterBottom>
                  誕生日や住んでいる地域など、情報を登録すると、あなたに合った限定ギフトやクーポンが届くかも！
                </StyledCaptionText>
              </Grid>
            </Grid>
          </Grid>

          <Grid size="grow">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={4}
              >
                <StyledFormContainer size="grow">
                  <FormControl component="fieldset">
                    <StyledGenderLabel component="legend">
                      性別
                    </StyledGenderLabel>
                    <Box mt={2}>
                      <ButtonGroup variant="outlined" disableElevation>
                        {genderCodes.map((genderCodeMaster) => (
                          <StyledGenderSelectionButton
                            key={genderCodeMaster.code}
                            isSelected={genderCode === genderCodeMaster.code}
                            onClick={() =>
                              handleChangeGender(genderCodeMaster.code)
                            }
                            data-gtm-click="userPage-profilePage-newPage-genderSelectionButton"
                          >
                            {genderCodeMaster.name}
                          </StyledGenderSelectionButton>
                        ))}
                      </ButtonGroup>
                    </Box>
                  </FormControl>
                </StyledFormContainer>
                <StyledFormContainer size="grow">
                  <FormControl component="fieldset">
                    <StyledBirthdayLabel component="legend">
                      生年月日
                    </StyledBirthdayLabel>
                    <StyledFormGroup
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid size="grow">
                        <TextField
                          id="user-profile-birth-year"
                          variant="outlined"
                          size="small"
                          placeholder="1980"
                          required
                          value={null}
                          autoComplete="bday-year"
                          onChange={handleChangeBirthdayYear}
                          slotProps={{
                            htmlInput: {
                              inputMode: 'numeric',
                              style: { textAlign: 'center' },
                              maxLength: 4,
                              minLength: 4,
                              pattern: '^(19|20)\\d{2}$',
                            },
                          }}
                        />
                      </Grid>
                      <StyledDateText variant="caption">年</StyledDateText>
                      <Grid size="grow">
                        <TextField
                          id="user-profile-birth-month"
                          variant="outlined"
                          size="small"
                          placeholder="01"
                          required
                          value={null}
                          autoComplete="bday-month"
                          onChange={handleChangeBirthdayMonth}
                          slotProps={{
                            htmlInput: {
                              inputMode: 'numeric',
                              style: { textAlign: 'center' },
                              maxLength: 2,
                              minLength: 1,
                              pattern: '^(0?[1-9]|1[012])$',
                            },
                          }}
                        />
                      </Grid>
                      <StyledDateText variant="caption">月</StyledDateText>
                      <Grid size="grow">
                        <TextField
                          id="user-profile-birth-day"
                          variant="outlined"
                          size="small"
                          placeholder="01"
                          required
                          value={null}
                          autoComplete="bday-day"
                          onChange={handleChangeBirthdayDay}
                          slotProps={{
                            htmlInput: {
                              inputMode: 'numeric',
                              style: { textAlign: 'center' },
                              maxLength: 2,
                              minLength: 1,
                              pattern: '^(0?[1-9]|[12]\\d|3[01])$',
                            },
                          }}
                        />
                      </Grid>
                      <StyledDateText variant="caption">日</StyledDateText>
                    </StyledFormGroup>
                  </FormControl>
                </StyledFormContainer>
                <StyledFormContainer size="grow">
                  <FormControl fullWidth>
                    <StyledPrefectureLabel id="user-profile-prefecture-label">
                      お住まいの地域
                    </StyledPrefectureLabel>
                    <Select
                      required
                      labelId="user-profile-prefecture-label"
                      id="user-profile-prefecture"
                      value={prefectureCode}
                      variant="standard"
                      onChange={handleChangePrefecture}
                      placeholder="お住まいの地域を選択"
                    >
                      {prefectureCodes.map((prefectureCodeMaster) => (
                        <MenuItem
                          key={prefectureCodeMaster.code}
                          value={prefectureCodeMaster.code}
                        >
                          {prefectureCodeMaster.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledFormContainer>
                <Grid size="grow">
                  <StyledCaptionText variant="caption" gutterBottom>
                    <Link
                      data-cy="termsOfServiceLink"
                      component={RouterLink}
                      to={PATHS.TERMS_OF_SERVICE}
                      style={{ textDecoration: 'none' }}
                    >
                      サービス利用規約
                    </Link>
                    に同意したことになります
                  </StyledCaptionText>
                  <StyledSubmitButton
                    disableElevation
                    data-cy="submitButton"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={!isReadyToSubmit}
                    loading={isLoading}
                    onClick={pushDatalayer}
                  >
                    登録
                  </StyledSubmitButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </StyledBaseBox>
    </Container>
  </>
);

export { Presenter };
