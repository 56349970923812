import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import notFoundIcon from '/@/assets/not_found_icon.svg';

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const StyledTopPageLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  margin: theme.spacing(4),
}));

const StyledTopPageLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-cy="notFoundPage"
    >
      <img
        src={notFoundIcon}
        alt="404 not found"
        width="100%"
        height="250"
        data-cy="notFoundIcon"
      />
      <StyledSubtitle variant="body2">
        お探しのページが見つかりませんでした。
      </StyledSubtitle>
      <Typography variant="caption">URLをご確認いただくか、</Typography>
      <Typography variant="caption">
        サービストップよりページをお探しください。
      </Typography>
      <StyledTopPageLink to={PATHS.ROOT}>
        <StyledTopPageLinkText variant="body2">
          ← トップページへ戻る
        </StyledTopPageLinkText>
      </StyledTopPageLink>
    </Grid>
  </MainLayout>
);

export { Presenter };
