import React from 'react';
import { Card, CardProps, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BrandBase } from '/@/store/app/shared/types';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
  minWidth: '160px',
  textAlign: 'left',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down(375)]: {
    width: '100%',
  },
  [theme.breakpoints.between(375, 600)]: {
    width: 'calc((100% - 1rem) / 2)',
  },
  [theme.breakpoints.up(600)]: {
    width: 'calc((100% - 2rem) / 3)',
  },
}));
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  border: '1px solid ' + theme.palette.grey.A200,
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: '-webkit-fill-available',
}));
const StyledItemTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  fontSize: '0.75rem',
  lineHeight: '1',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));
const StyledDiscountTexts = styled('div')({
  fontSize: '0.675rem',
  lineHeight: '1',
  fontWeight: 900,
});
const StyledDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.625rem',
  lineHeight: '1.5',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));

type Props = {
  index: number;
  brand: BrandBase;
  isRecommended: boolean;
  isLastItem?: boolean;
  navigateItemPage: () => void;
};

const Presenter: React.VFC<Props> = ({
  index,
  brand,
  isRecommended,
  isLastItem,
  navigateItemPage,
}) => (
  <StyledCard
    key={index}
    variant="outlined"
    style={{
      height: isRecommended ? '6.625rem' : '4rem',
      marginRight: isLastItem ? 'auto' : 0,
    }}
    component="button"
    onClick={navigateItemPage}
    data-cy="brandCard"
    data-gtm-click="shared-brandContents-brandCard-card"
  >
    <StyledLogoAndTitle>
      <StyledLogoContainer>
        <StyledLogo src={brand.iconUrl} alt={brand.name} />
      </StyledLogoContainer>
      <StyledRightContent>
        <StyledItemTitle>{brand.name}</StyledItemTitle>
        <StyledDiscountTexts>
          {isRecommended ? (
            <>
              {brand.currentPointBackCampaign?.discountPercentage && (
                <DiscountPercentageLabel
                  discountPercentage={
                    brand.currentPointBackCampaign?.discountPercentage
                  }
                  labelSize="small"
                />
              )}
            </>
          ) : (
            brand.currentPointBackCampaign?.discountPercentage && (
              <DiscountPercentageLabel
                discountPercentage={
                  brand.currentPointBackCampaign?.discountPercentage
                }
                isEmphased={false}
                labelSize="small"
              />
            )
          )}
        </StyledDiscountTexts>
      </StyledRightContent>
    </StyledLogoAndTitle>
    {isRecommended && brand.currentPointBackCampaign?.description && (
      <StyledDescription>
        {brand.currentPointBackCampaign.description}
      </StyledDescription>
    )}
  </StyledCard>
);

export { Presenter };
