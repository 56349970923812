import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItemText,
  ListSubheader,
  Divider,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/system';
import menuIcon from '/@/assets/menu_icon.svg';
import giftWalletLogo from '/@/assets/gift_wallet_logo.svg';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

export const HEADER_HEIGHT = 48;
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: 'none',
  borderRight: 'none',
  borderLeft: 'none',
}));
const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  height: `${HEADER_HEIGHT}px`,
  minHeight: `${HEADER_HEIGHT}px`,
  padding: theme.spacing(1, 2),
}));
const StyledMenuIconButton = styled(IconButton)({
  padding: 0,
});
const StyledGiftWalletLogoLink = styled(RouterLink)(({ theme }) => ({
  margin: 'auto',
  marginLeft: 0,
  height: theme.spacing(3),
}));
const StyledGiftWalletLogo = styled('img')(({ theme }) => ({
  margin: 'auto',
  marginLeft: 0,
  height: theme.spacing(3),
}));
const StyledMenuDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('md')]: {
      width: '10%',
    },
  },
}));
const StyledMenuListSubheader = styled(ListSubheader)({
  fontSize: '0.75rem',
});
const StyledMenuListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
  },
});

type Props = {
  isLogoClickable: boolean;
  isMenuVisible: boolean;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
};

const Presenter: React.VFC<Props> = ({
  isLogoClickable,
  isMenuVisible,
  isMenuOpened,
  handleToggleMenu,
  handleClickGlobalMenu,
}) => (
  <>
    <StyledAppBar
      position="fixed"
      variant="outlined"
      color="default"
      data-cy="header"
    >
      <StyledToolBar>
        {isLogoClickable ? (
          <StyledGiftWalletLogoLink
            to={PATHS.ROOT}
            data-cy="clickableGiftWalletLogo"
          >
            <img height={24} src={giftWalletLogo} alt="ロゴ" />
          </StyledGiftWalletLogoLink>
        ) : (
          <StyledGiftWalletLogo
            height={24}
            src={giftWalletLogo}
            alt="ロゴ"
            data-cy="unclickableGiftWalletLogo"
          />
        )}
        {isMenuVisible && (
          <StyledMenuIconButton
            color="primary"
            aria-label="menu-icon-button"
            onClick={() => {
              handleToggleMenu();
              handleClickGlobalMenu();
            }}
            edge="start"
            data-cy="menuIcon"
            data-gtm-click="templates-header-globalHeader-menuButton"
          >
            <img height={18} width={16} src={menuIcon} alt="メニューアイコン" />
          </StyledMenuIconButton>
        )}
      </StyledToolBar>
    </StyledAppBar>

    <StyledMenuDrawer
      anchor="right"
      open={isMenuOpened}
      onOpen={() => {}} // Do nothing
      onClose={handleToggleMenu}
    >
      <List data-cy="menuList" component="nav" aria-label="menu-list">
        <StyledMenuListSubheader>ギフト管理</StyledMenuListSubheader>
        <ListItemButton
          data-cy="listItemHome"
          component={RouterLink}
          to={PATHS.HOME}
        >
          <StyledMenuListItemText primary="マイギフト" />
        </ListItemButton>
        <ListItemButton
          data-cy="listItemGetGift"
          component={RouterLink}
          to={PATHS.PROMOTIONS}
        >
          <StyledMenuListItemText primary="もらえるギフト" />
        </ListItemButton>

        <Divider component="li" />

        <StyledMenuListSubheader>ヘルプ</StyledMenuListSubheader>
        <ListItemButton
          component={RouterLink}
          to={PATHS.FREQUENTLY_ASKED_QUESTIONS}
        >
          <StyledMenuListItemText
            data-cy="listItemFrequentlyAskedQuestions"
            primary="よくあるご質問"
          />
        </ListItemButton>
        <ListItemButton component={RouterLink} to={PATHS.INQUIRIES_NEW}>
          <StyledMenuListItemText
            data-cy="listItemInquires"
            primary="お問い合わせ"
          />
        </ListItemButton>
        <ListItemButton component={RouterLink} to={PATHS.TERMS_OF_SERVICE}>
          <StyledMenuListItemText
            data-cy="listItemTermsOfServices"
            primary="サービス利用規約"
          />
        </ListItemButton>
      </List>
    </StyledMenuDrawer>
  </>
);

export { Presenter };
