import React from 'react';
import { Grid2 as Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import { HeroSection } from './heroSection/Index';
import { LoginSection } from './loginSection/Index';
import { AboutSection } from './aboutSection/Index';
import { FeatureSection } from './featureSection/Index';
import { UsageSection } from './usageSection/Index';
import { SummarySection } from './summarySection/Index';
import { Footer } from '/@/components/templates/footer/Index';
import { LoginOption } from '/@/components/shared/loginButton/Index';

const StyledContainer = styled(Container)({
  padding: 0,
});
const StyledLoginSectionContainer = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
}));
const StyledAboutSectionContainer = styled('section')(({ theme }) => ({
  margin: theme.spacing(2),
}));
const StyledFeatureSectionContainer = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
const StyledUsageSectionContainer = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  backgroundColor: theme.palette.background.hint,
}));
const StyledSummarySectionContainer = styled('section')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
}));

type Props = LoginOption & {
  isSerialCodeOutOfPeriod: boolean;
  errorMessage: string | null;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  isSerialCodeOutOfPeriod,
  errorMessage,
}) => (
  <Grid container direction="column" alignContent="center">
    <section>
      <StyledContainer maxWidth="sm">
        <HeroSection />
      </StyledContainer>
    </section>
    <StyledLoginSectionContainer>
      <StyledContainer maxWidth="sm">
        <LoginSection
          url={url}
          origin={origin}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
          isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
          errorMessage={errorMessage}
        />
      </StyledContainer>
    </StyledLoginSectionContainer>
    <StyledAboutSectionContainer>
      <StyledContainer maxWidth="sm">
        <AboutSection />
      </StyledContainer>
    </StyledAboutSectionContainer>
    <StyledFeatureSectionContainer>
      <StyledContainer maxWidth="sm">
        <FeatureSection />
      </StyledContainer>
    </StyledFeatureSectionContainer>
    <StyledUsageSectionContainer>
      <StyledContainer maxWidth="sm">
        <UsageSection />
      </StyledContainer>
    </StyledUsageSectionContainer>
    <StyledSummarySectionContainer>
      <StyledContainer maxWidth="sm">
        <SummarySection
          url={url}
          origin={origin}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        />
      </StyledContainer>
    </StyledSummarySectionContainer>
    <Footer />
  </Grid>
);

export { Presenter };
