import { getItemsConnection } from '/@/api/graphql/internalApi/getItemsConnection';
import type { GetItemsConnectionResponse } from '/@/api/graphql/internalApi/getItemsConnection';
import type { AppThunk } from '/@/store';
import {
  getItemsConnectionStart,
  getItemsConnectionSuccess,
  getItemsConnectionFailure,
} from './actions';
import {
  ItemType_SortConditionTargetEnumType,
  type GetItemsConnectionQueryVariables,
  SortDirectionEnum,
  PageInfo,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getSearchedItemsConnectionThunk =
  (after: PageInfo['endCursor'] | null = null): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const itemSearchCondition = getState().app?.itemSearchCondition;
    const searchCondition = {
      after: after,
      brandUidEq: itemSearchCondition?.brandUid ?? undefined,
      withPointsGteq: itemSearchCondition?.minPoint ?? undefined,
      withPointsLteq: itemSearchCondition?.maxPoint ?? undefined,
    };
    return dispatch(
      getItemsConnectionThunk(searchCondition, ITEMS_PATTERN.SEARCHED),
    );
  };

const getItemsConnectionThunk =
  (
    variables: GetItemsConnectionQueryVariables,
    itemsPattern: ItemsPatternTypes,
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(getItemsConnectionStart(itemsPattern));
    try {
      const itemSearchCondition = getState().app?.itemSearchCondition;

      // MEMO: 現在はユーザ側でソート指定しないため、brandUidの有無でソート順を変えている
      // TODO: ユーザ側でソート指定できるようにする場合は、itemSearchConditionにorderKeyを追加
      const orderKey = itemSearchCondition?.brandUid
        ? OrderKeyEnum.BRAND_ASSIGNED_DEFAULT
        : OrderKeyEnum.DEFAULT;

      const defaultVariables: GetItemsConnectionQueryVariables = {
        after: null,
        sortConditions: sortConditionByOrderKey(orderKey),
      };

      const response = await getItemsConnection(
        Object.assign({}, defaultVariables, variables),
      );
      dispatch(getItemsConnectionSuccessThunk(response, itemsPattern));
    } catch (error) {
      dispatch(getItemsConnectionFailureThunk(error as ApiError, itemsPattern));
    }
  };

const getItemsConnectionSuccessThunk =
  (
    response: GetItemsConnectionResponse,
    itemsPattern: ItemsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { items } = response.data!;
    dispatch(getItemsConnectionSuccess(items, itemsPattern));
    return Promise.resolve();
  };

const getItemsConnectionFailureThunk =
  (error: ApiError, itemsPattern: ItemsPatternTypes): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getItemsConnectionFailure(error, itemsPattern));
    return dispatch(handleErrorThunk(error));
  };

export const OrderKeyEnum = {
  DEFAULT: 'default',
  BRAND_ASSIGNED_DEFAULT: 'brand_assigned_default',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
} as const;

export type OrderKeyEnumType = (typeof OrderKeyEnum)[keyof typeof OrderKeyEnum];

const sortConditionByOrderKey = (orderKey: OrderKeyEnumType) => {
  switch (orderKey) {
    case OrderKeyEnum.DEFAULT:
      return [
        {
          target: ItemType_SortConditionTargetEnumType.Point,
          direction: SortDirectionEnum.Asc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.BrandPosition,
          direction: SortDirectionEnum.Asc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.Position,
          direction: SortDirectionEnum.Asc,
        },
      ];
    case OrderKeyEnum.BRAND_ASSIGNED_DEFAULT:
      return [
        {
          target: ItemType_SortConditionTargetEnumType.Position,
          direction: SortDirectionEnum.Asc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.Point,
          direction: SortDirectionEnum.Asc,
        },
      ];
    case OrderKeyEnum.PRICE_ASC:
      return [
        {
          target: ItemType_SortConditionTargetEnumType.Point,
          direction: SortDirectionEnum.Asc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.BrandPosition,
          direction: SortDirectionEnum.Asc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.Position,
          direction: SortDirectionEnum.Asc,
        },
      ];
    case OrderKeyEnum.PRICE_DESC:
      return [
        {
          target: ItemType_SortConditionTargetEnumType.Point,
          direction: SortDirectionEnum.Desc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.BrandPosition,
          direction: SortDirectionEnum.Asc,
        },
        {
          target: ItemType_SortConditionTargetEnumType.Position,
          direction: SortDirectionEnum.Asc,
        },
      ];
    default:
      return undefined;
  }
};

const ITEMS_PATTERN = {
  SEARCHED: 'searched',
};
type ItemsPatternTypes = (typeof ITEMS_PATTERN)[keyof typeof ITEMS_PATTERN];

export type { ItemsPatternTypes };

export {
  ITEMS_PATTERN,
  getItemsConnectionThunk,
  getSearchedItemsConnectionThunk,
};
